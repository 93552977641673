.add-to-cart-button {
  background-color: var(--secondary-colour);
  color: white;
  font-weight: 600;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  position: relative;    
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    border-radius: 100%; 
    opacity: 0;
    transition: all 0.4s ease;
  }
  &:hover {
    background-color: var(--primary-colour);
  }
  &:active::before {
    width: 200px;
    height: 200px;
    opacity: 1;
  }
  .button-text {
    z-index: 2;
  }
}
