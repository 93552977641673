.product-card {
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 25px;
  width: 300px;
  background-color: var(--foreground-colour);
  position: relative;

  .card-image {
    height: 200px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 80px;

    label {
      font-weight: bold;
    }

    .description {
      margin-top: 10px;
    }
  }

  .purchase-container {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 30px;

    .price {
      font-weight: bold;
      font-size: 18px;
    }
  }
}