footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: var(--primary-colour);
}
.footerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 0;
}
.text {
    text-align: center;
}
.social_links {
    text-align: center;
}
hr {
    height: 1px;
    border: none;
    background-color: var(--text-colour);
    width: 80%;
    margin: auto;
}
.logo_footer_div {
    width: 100%;
    max-width: 200px;
}
.logo_footer_div_sub {
    width: 100%;
    max-width: 100px;
}
.logo_footer {
    max-width: 100%;
}
@media only screen and (max-width: 550px){
    .footerContent {
        flex-direction: column;
    }
    address {
        text-align: center;
    }
    .logo_footer_div {
        margin: 0 auto;
    }
    .logo_footer_div_sub {
        margin: 0 auto;
    }
}