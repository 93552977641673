.home-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    display: block;
    font-weight: bold;
  }

  .hero-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 76.5px);

    .notification-bubble {
      background-color: #FFEDD0;
      color: #333;
      border: 1px solid #F0C47F;
      padding: 10px 15px;
      border-radius: 8px;
      text-align: center;
      z-index: 1000;

      .email {
        color: blue;
      }
    }

    .plate-images {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 30vh;

      img {
        object-fit: contain;
        max-width: 1000px;
        max-height: 45%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: space-around;

      .select-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin: 20px;
      }
    }

    @media only screen and (max-width: 1169px) {
      .plate-images {
        img {
          max-width: 90%;
        }
      }
    }

  }

  .user-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: sticky;
    bottom: 10px;

    .price {
      margin: 20px 0;
    }

    input[type="text"] {
      font-family: 'Registration';
      width: 80%;
      height: 50px;
      padding: 10px;
      border: 2px solid var(--primary-colour);
      border-radius: 5px;
      font-size: 36px;
      text-align: center;
      margin-bottom: 30px;
    }

    input[type="text"]:focus {
      border: 2px solid var(--primary-colour);
    }
  }

  @media only screen and (max-width: 1169px) {
    .plate-images {
      img {
        max-width: 90%;
      }
    }

    .user-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: sticky;
      bottom: 0px;

      .price {
        margin: 0;
      }

      input[type="text"] {
        font-family: 'Registration';
        width: 80%;
        height: 50px;
        padding: 10px;
        border: 2px solid var(--primary-colour);
        border-radius: 5px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 5px;
      }

      input[type="text"]:focus {
        border: 2px solid var(--primary-colour);
      }
    }
  }
}