.toggle-buttons { /* Container for the buttons */
    display: flex;
    border: 1px solid var(--primary-colour); /* Add a subtle border to the container */
    border-radius: 5px; /* Rounded corners for the container */
    overflow: hidden; /* Hide overflowing button backgrounds */
  }
  
  .toggle-buttons button {
    background-color: var(--foreground-colour);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1; /* Make buttons take equal width */
    color: black;
  }
  
  .toggle-buttons button:hover {
    background-color: var(--foreground-colour);
  }
  
  .toggle-buttons button.active {
    background-color: var(--secondary-colour);
    color: white;
  }
  
  .toggle-buttons button.active::before { /* Pseudo-element for the moving selection */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    transform: translateX(-100%); /* Initially hidden to the left */
    transition: transform 0.3s ease; /* Smooth transition for the movement */
  }
  
  .toggle-buttons button.active:hover::before {
    background-color: rgba(255, 255, 255, 0.5); /* More opaque on hover */
  }
  
  .toggle-buttons button:first-child {
    border-top-left-radius: 5px; /* Rounded corners for the first button */
    border-bottom-left-radius: 5px;
  }
  
  .toggle-buttons button:last-child {
    border-top-right-radius: 5px; /* Rounded corners for the last button */
    border-bottom-right-radius: 5px;
  }