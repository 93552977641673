header {
    width: 100%;
    height: 50px;
    align-items: center;
    background-color: var(--primary-colour);
    position: sticky;
    top: 0;
    z-index: 9999;

    .is-admin-button {
        position: absolute;
        left: 200px;
        top: 15px;
    }
}

.logo {
    height: 40px;
}

header .logo_item {
    margin: 5px 0;
}

header nav {
    width: 100%;
    height: 50px;
}

header nav ul {
    height: 50px;
    display: flex;
    list-style: none;
    font-size: 1.5rem;
}

header .main_ul {
    padding: 0 30px;
    justify-content: space-between;
}

.nav_links {
    width: 25%;
    justify-content: space-evenly;

    li {
        margin: auto;

        a {
            color: var(--text-colour);
        }
    }
}

.active_link {
    font-weight: bold;
}

@media only screen and (max-width: 1169px) {
    header {
        transition: 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1);

        .is-admin-button {
            left: 50px;
        }

        .nav_links {
            display: none;
        }

        .logo_item {
            margin-left: calc(50% - 80px);
            text-align: center;
        }
    }

    .hidden {
        height: 100vh;
        background-color: var(--foreground-colour);
    }

    #hidden {
        display: flex;
        position: absolute;
        left: 0px;
        top: 50px;
        height: 100vh;
        width: 100%;
        text-align: center;
        flex-direction: column;
    }

    .menu-btn {
        position: absolute;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }

    .menu-btn-burger {
        width: 30px;
        height: 3px;
        background: var(--text-colour);
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease-in-out;
    }

    .menu-btn-burger::before,
    .menu-btn-burger::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 3px;
        background: var(--text-colour);
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease-in-out;
    }

    .menu-btn-burger::before {
        transform: translateY(-10px);
    }

    .menu-btn-burger::after {
        transform: translateY(10px);
    }

    .menu-btn.open .menu-btn-burger {
        background: transparent;
        box-shadow: none;
    }

    .menu-btn.open .menu-btn-burger::before {
        transform: rotate(45deg);
    }

    .menu-btn.open .menu-btn-burger::after {
        transform: rotate(-45deg);
    }
}