.product-modal {
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 25px;
  height: 80%;
  width: 80%;
  background-color: var(--foreground-colour);
  position: relative;

  .close-modal {
    position: absolute;
    top: 0;
    left: 0;
  }

  .card-image {
    height: 200px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 80px;

    .description {
      margin-top: 10px;
    }

    .purchase-container {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;

      .configure-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .discount {
          height: 18px;
          grid-column: 2;
          color: red;
        }

        .quantity-selector-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .price {
          font-weight: bold;
          font-size: 18px;
        }

      }

      .button-container {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}