.cards {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.modal {
  background: linear-gradient(to bottom, rgba(114, 133, 150, 0.8), rgba(216, 230, 243, 0.6));
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}