* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Registration';
  src: url('./assets/fonts/UKNumberPlate.ttf') format('truetype');
}

:root {
  --text-colour: #000000;
  --primary-colour: #ffffff;
  --secondary-colour: rgb(27,56,102);
  --background-colour: #e0e0e0;
  --foreground-colour: #b6b6b6;
}

.dark-mode {
  --text-colour: white;
  --primary-colour: black;
  --secondary-colour: rgb(77,77,77);
  --background-colour: rgb(66,66,66);
  --foreground-colour: rgb(97,97,97);
}

body {
  color: var(--text-colour);
  a {
    text-decoration: none;
    color: var(--text-colour);
  }
  margin: 0;
  background-color: var(--background-colour);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  outline: none; /* Remove the outline completely */
  /* Or customize it: */
}